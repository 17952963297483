<template>
  <section id="specificity-section" class="section">
    <section-title :text="'Co wyróżnia ofertę brutto.pl?'" />
    <div id="infos-2" class="grid-container">
      <article class="item1 info-box">
        <h3 class="article-title">Szybki proces</h3>
        <p class="article-text">Cały proces odbywa się w 100% online, a&nbsp;na&nbsp;decyzję o przyznaniu finansowania wpływają Twoje wyniki sprzedażowe, które weryfikujemy na&nbsp;podstawie danych z&nbsp;konta w&nbsp;Sky-Shop.</p>
        <SvgImage>
          <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M48.0677 35.6801C48.0722 30.5704 46.281 25.6172 42.9994 21.6646C39.7177 17.712 35.1487 15.0044 30.0707 14.0032C24.9928 13.002 19.7201 13.7692 15.1511 16.174C10.5822 18.5787 6.99953 22.4724 5.01374 27.1914C3.02794 31.9105 2.76184 37.1629 4.26074 42.0539C5.75964 46.9448 8.93078 51.1716 13.2339 54.0141C17.537 56.8566 22.7059 58.1388 27.8597 57.6424C33.0135 57.146 37.8335 54.9016 41.4983 51.2916C43.5793 49.2431 45.2307 46.8099 46.3579 44.1311C47.4852 41.4524 48.0662 38.5806 48.0677 35.6801ZM42.4015 19.1502C46.699 23.3907 49.3541 29.2248 49.3541 35.6801C49.357 38.7476 48.7441 41.7852 47.5508 44.6177C46.3575 47.4502 44.6073 50.0214 42.4015 52.1829C38.1312 56.4235 32.219 59.0164 25.677 59.0164C22.5688 59.0206 19.4903 58.42 16.6175 57.2491C13.7447 56.0782 11.134 54.3598 8.93491 52.1924C6.7358 50.0249 4.99139 47.4509 3.80142 44.6175C2.61145 41.7842 1.9993 38.7471 2 35.6801C2 29.2248 4.65502 23.3907 8.92525 19.1502C13.2226 14.9364 19.1352 12.3168 25.6772 12.3168C28.7836 12.3127 31.8603 12.9143 34.7304 14.0869C37.6005 15.2596 40.2075 16.9803 42.4017 19.1501L42.4015 19.1502Z"/>
            <path d="M56.9092 17.7186C56.9524 17.7895 56.9809 17.8681 56.9932 17.95C57.0056 18.0318 57.0015 18.1152 56.9811 18.1955C56.9608 18.2757 56.9247 18.3512 56.8748 18.4177C56.8249 18.4842 56.7622 18.5403 56.6903 18.5828C56.6184 18.6254 56.5388 18.6536 56.4558 18.6658C56.3729 18.678 56.2883 18.6739 56.207 18.6538C56.1257 18.6338 56.0492 18.5981 55.9818 18.5489C55.9144 18.4996 55.8575 18.4378 55.8144 18.3668C52.7311 13.1631 48.3249 8.84327 43.0315 5.83439C37.7559 2.82938 31.7688 1.2546 25.6774 1.26976C25.212 1.26976 24.8015 1.26976 24.4182 1.29675C24.0077 1.29675 23.597 1.32373 23.159 1.35078C23.0761 1.35926 22.9922 1.35118 22.9125 1.32705C22.8328 1.30292 22.7589 1.26321 22.695 1.21029C22.6312 1.15737 22.5787 1.09232 22.5409 1.01901C22.503 0.945696 22.4805 0.865619 22.4746 0.783542C22.4691 0.700152 22.4803 0.616508 22.5076 0.537399C22.5349 0.458289 22.5777 0.385275 22.6337 0.322542C22.6897 0.25981 22.7576 0.208593 22.8337 0.17183C22.9097 0.135066 22.9924 0.113481 23.0769 0.108309C23.4875 0.0813257 23.9254 0.0542616 24.3634 0.0269829C24.8561 0.0269829 25.2667 6.17047e-07 25.6773 6.17047e-07C31.9991 -0.00115986 38.2105 1.63456 43.6911 4.74376C49.1716 7.85296 53.7294 12.3268 56.9092 17.7183L56.9092 17.7186Z"/>
            <path d="M49.6828 17.9618C49.9017 18.2319 49.8196 18.637 49.546 18.8531C49.4052 18.9449 49.2349 18.982 49.068 18.957C48.9011 18.9321 48.7495 18.8469 48.6426 18.718C45.9836 15.1881 42.5247 12.3207 38.5423 10.345C34.5713 8.36362 30.1819 7.33633 25.7321 7.34696C25.5651 7.34696 25.405 7.28151 25.2869 7.16502C25.1688 7.04852 25.1025 6.89053 25.1025 6.72577C25.1025 6.56102 25.1688 6.40301 25.2869 6.28651C25.405 6.17002 25.5651 6.10457 25.7321 6.10457C30.3861 6.09401 34.9777 7.16134 39.1375 9.22072C43.2973 11.2801 46.9092 14.2741 49.6828 17.9618Z"/>
            <path d="M6.24287 22.2562C6.29671 22.1921 6.36286 22.1391 6.43748 22.1003C6.5121 22.0614 6.59374 22.0375 6.67775 22.0299C6.76176 22.0222 6.84646 22.031 6.92702 22.0557C7.00757 22.0805 7.08243 22.1206 7.14724 22.1739C7.21204 22.2272 7.26553 22.2926 7.30469 22.3663C7.34384 22.4401 7.36784 22.5207 7.37536 22.6036C7.38288 22.6865 7.37377 22.7701 7.34851 22.8495C7.32325 22.9289 7.28234 23.0027 7.22816 23.0665C6.81767 23.5256 6.51641 24.0388 6.37968 24.525V24.5519C6.27023 24.9301 6.27023 25.2812 6.37968 25.5513C6.84508 26.5237 8.51487 26.9288 10.8688 26.7668C13.4144 26.6047 16.6718 25.7404 20.0112 24.2548C23.3779 22.7693 26.1973 20.9327 28.0039 19.15C29.6737 17.5024 30.4949 16.0169 30.0295 15.0176C29.8342 14.625 29.518 14.3036 29.1262 14.0992C28.5729 13.8191 27.9675 13.6537 27.347 13.6131C27.2643 13.606 27.1839 13.5829 27.1102 13.5451C27.0366 13.5074 26.9712 13.4556 26.9178 13.3929C26.8645 13.3302 26.8242 13.2578 26.7992 13.1797C26.7742 13.1016 26.765 13.0194 26.7722 12.9378C26.7794 12.8563 26.8028 12.7769 26.8411 12.7042C26.8794 12.6316 26.9318 12.5671 26.9953 12.5144C27.0589 12.4617 27.1323 12.4219 27.2114 12.3973C27.2906 12.3726 27.3739 12.3635 27.4565 12.3706C28.2508 12.4107 29.0264 12.6227 29.7284 12.9916C30.4401 13.3698 30.9328 13.8829 31.2064 14.5041C31.8908 16.0167 30.9601 18.0424 28.9072 20.0412C26.9912 21.9318 24.0349 23.8496 20.5586 25.4161C17.0549 26.9556 13.6332 27.847 10.9508 28.0361C8.04931 28.2252 5.91429 27.5769 5.20263 26.0644C4.9563 25.4972 4.95627 24.849 5.14784 24.2007V24.1737C5.37223 23.4657 5.74536 22.8123 6.24287 22.2562Z"/>
            <path d="M4.18999 45.3494C4.12705 45.1977 4.123 45.0285 4.17857 44.874C4.23415 44.7196 4.34549 44.5907 4.49127 44.5121C4.64416 44.4436 4.81803 44.4363 4.97627 44.4918C5.13451 44.5472 5.26482 44.661 5.33974 44.8093L5.36711 44.8362C6.43463 47.1861 9.66456 48.4555 14.0442 48.5095C18.5606 48.6176 24.254 47.4021 30.0297 44.8362C35.6957 42.3244 40.2396 39.0022 43.0863 35.68C45.9604 32.3848 47.1374 29.0896 46.0971 26.6587C45.9878 26.3346 46.1246 25.9564 46.453 25.8214C46.7815 25.7134 47.1648 25.8484 47.3015 26.1726C48.506 29.0896 47.2468 32.844 44.0716 36.5173C41.0881 39.9745 36.4075 43.3776 30.5497 45.9977C24.6098 48.6446 18.7247 49.86 14.0167 49.779C9.14438 49.6977 5.47642 48.1855 4.18991 45.3494H4.18999Z"/>
            <path d="M18.8339 31.5475C19.135 31.3855 19.5182 31.5206 19.6824 31.8447C19.8194 32.1688 19.7098 32.5469 19.3813 32.6819C14.8648 34.8157 11.3339 37.3276 9.00709 39.7314C6.62571 42.1893 5.53081 44.4849 6.10551 45.9708C6.15795 46.1286 6.14757 46.3002 6.07639 46.4507C6.00521 46.6012 5.87863 46.7194 5.72235 46.7811C5.36656 46.8892 5.01063 46.7271 4.90124 46.4029C4.16217 44.4312 5.33921 41.6763 8.07638 38.8672C10.5127 36.3552 14.1806 33.7354 18.8339 31.5475Z"/>
            <path d="M47.7666 27.361C47.7982 27.4372 47.8141 27.5188 47.8133 27.6011C47.8125 27.6834 47.7949 27.7647 47.7618 27.8402C47.7286 27.9157 47.6805 27.9839 47.6202 28.0408C47.5599 28.0977 47.4887 28.1421 47.4107 28.1713C47.3337 28.2039 47.2507 28.2206 47.167 28.2204C47.0832 28.2202 47.0003 28.2032 46.9235 28.1703C46.8466 28.1375 46.7773 28.0895 46.7199 28.0292C46.6624 27.969 46.6181 27.8979 46.5895 27.8202C46.0695 26.6048 44.0438 26.0375 41.0877 26.0916C37.9125 26.1456 33.7244 26.9558 29.3449 28.4684C29.2659 28.4984 29.1817 28.5125 29.0971 28.5096C29.0126 28.5068 28.9294 28.4871 28.8528 28.4518C28.7761 28.4165 28.7075 28.3662 28.651 28.3041C28.5945 28.242 28.5512 28.1692 28.5238 28.0902C28.496 28.0105 28.4845 27.926 28.4902 27.8419C28.4958 27.7577 28.5184 27.6755 28.5567 27.6C28.5949 27.5245 28.648 27.4574 28.7129 27.4024C28.7777 27.3475 28.853 27.3058 28.9344 27.2799C33.4508 25.7134 37.7482 24.8761 41.0877 24.822C44.5639 24.7681 47.0549 25.6054 47.7666 27.361Z"/>
            <path d="M17.356 19.8524C17.2794 19.7014 17.2652 19.5269 17.3162 19.3658C17.3673 19.2046 17.4797 19.0692 17.6298 18.9881C17.7068 18.9499 17.7908 18.9273 17.8769 18.9216C17.963 18.9159 18.0493 18.9272 18.1309 18.9549C18.2125 18.9826 18.2876 19.026 18.3519 19.0828C18.4163 19.1395 18.4684 19.2083 18.5054 19.2852L36.6536 55.6941C36.6933 55.7679 36.717 55.849 36.7232 55.9324C36.7294 56.0157 36.718 56.0994 36.6896 56.1782C36.6613 56.2569 36.6167 56.329 36.5586 56.3898C36.5005 56.4506 36.4303 56.4988 36.3523 56.5314C36.0512 56.6934 35.6681 56.5584 35.5039 56.2612L17.356 19.8524Z"/>
          </svg>
        </SvgImage>
      </article>
      <article class="item2 info-box">
        <h3 class="article-title">Błyskawiczna wypłata środków</h3>
        <p class="article-text">W&nbsp;dniu podpisania umowy kodem SMS przekażemy finansowanie na&nbsp;Twój rachunek bankowy podany w&nbsp;panelu administratora Sky-Shop. Wpłata zostanie zrealizowana przelewem ekspresowym, dzięki czemu środki będą od&nbsp;razu do&nbsp;Twojej dyspozycji.</p>
        <SvgImage>
          <svg width="61" height="60" viewBox="0 0 61 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0)">
              <path d="M33.9558 44.2847H17.3907V43.1318H32.806V27.2027H17.5183V26.0498H33.9558V44.2847Z"/>
              <path d="M37.2348 39.9716H36.0851V23.7863H17.6036V22.6334H37.2348V39.9716Z"/>
              <path d="M17.3907 39.3737V38.1777C19.1791 38.1777 20.627 36.7257 20.627 34.9748C20.6271 34.5486 20.5436 34.1265 20.381 33.7327C20.2184 33.3389 19.9799 32.9811 19.6793 32.6797C19.3788 32.3784 19.022 32.1393 18.6293 31.9762C18.2366 31.8132 17.8158 31.7293 17.3907 31.7294V30.5765C18.554 30.5765 19.6697 31.04 20.4922 31.8649C21.3148 32.6897 21.7769 33.8085 21.7769 34.9751C21.7769 36.1417 21.3148 37.2605 20.4922 38.0854C19.6697 38.9103 18.554 39.3737 17.3907 39.3737Z"/>
              <path d="M29.7401 33.9929C29.6646 33.9903 29.5903 33.9728 29.5215 33.9413C29.4528 33.9098 29.3907 33.865 29.3393 33.8094C29.2879 33.7539 29.2479 33.6887 29.2217 33.6176C29.1954 33.5465 29.1835 33.4709 29.1865 33.3952V29.8079C29.1835 29.7322 29.1954 29.6566 29.2217 29.5855C29.2479 29.5144 29.2879 29.4492 29.3393 29.3937C29.3907 29.3381 29.4528 29.2933 29.5215 29.2618C29.5903 29.2303 29.6646 29.2127 29.7401 29.2101C29.8189 29.2082 29.8972 29.2224 29.9704 29.2517C30.0436 29.2811 30.11 29.3251 30.1657 29.381C30.2215 29.4369 30.2654 29.5035 30.2947 29.5769C30.324 29.6503 30.3381 29.7289 30.3362 29.8079V33.3952C30.3381 33.4742 30.324 33.5528 30.2947 33.6262C30.2654 33.6996 30.2215 33.7662 30.1657 33.8221C30.11 33.878 30.0436 33.922 29.9704 33.9513C29.8972 33.9807 29.8189 33.9949 29.7401 33.9929Z"/>
              <path d="M36.7665 60H21.1807C19.07 59.996 17.0468 59.1534 15.5543 57.6566C14.0617 56.1599 13.2215 54.1309 13.2175 52.0142V7.9858C13.2215 5.86907 14.0618 3.84018 15.5543 2.34343C17.0468 0.846668 19.07 0.00401809 21.1807 0H36.7665C38.8773 0.00401809 40.9004 0.846668 42.3929 2.34343C43.8855 3.84018 44.7258 5.86907 44.7298 7.9858V15.587C44.7298 15.8859 44.4742 16.1848 44.1762 16.1848C44.0191 16.1815 43.8694 16.1175 43.7583 16.0061C43.6472 15.8947 43.5833 15.7445 43.5801 15.587V7.9858C43.5748 6.17527 42.8553 4.4404 41.5786 3.16015C40.302 1.87991 38.572 1.15831 36.7666 1.15301H21.1807C19.3753 1.15831 17.6454 1.87991 16.3688 3.16015C15.0922 4.4404 14.3726 6.17527 14.3673 7.9858V52.0143C14.3726 53.8248 15.0922 55.5597 16.3688 56.8399C17.6454 58.1202 19.3753 58.8418 21.1807 58.8471H36.7665C38.5719 58.8418 40.3019 58.1202 41.5785 56.84C42.8552 55.5597 43.5747 53.8248 43.58 52.0143V35.4448C43.5826 35.3691 43.6001 35.2946 43.6315 35.2256C43.6629 35.1567 43.7076 35.0946 43.763 35.043C43.8184 34.9914 43.8834 34.9513 43.9543 34.925C44.0252 34.8987 44.1006 34.8867 44.1761 34.8897C44.3221 34.8923 44.4614 34.9516 44.5647 35.0552C44.6679 35.1587 44.7271 35.2984 44.7297 35.4448V52.0143C44.7257 54.131 43.8854 56.1599 42.3928 57.6566C40.9003 59.1534 38.8772 59.996 36.7665 60Z"/>
              <path d="M28.8032 51.7152C28.5278 51.711 28.2574 51.7894 28.0266 51.9403C27.7959 52.0911 27.6153 52.3076 27.508 52.562C27.4007 52.8164 27.3715 53.0971 27.4242 53.3682C27.477 53.6393 27.6092 53.8885 27.804 54.0838C27.9987 54.2791 28.2472 54.4117 28.5176 54.4646C28.7879 54.5174 29.0678 54.4882 29.3215 54.3806C29.5751 54.273 29.791 54.0919 29.9414 53.8605C30.0919 53.6291 30.17 53.3579 30.1659 53.0818C30.1634 52.7201 30.019 52.374 29.764 52.1182C29.509 51.8625 29.1639 51.7177 28.8032 51.7152ZM28.8032 55.644C28.1262 55.642 27.4774 55.3714 26.9987 54.8913C26.52 54.4112 26.2501 53.7606 26.2482 53.0817C26.2594 52.4095 26.5335 51.7687 27.0114 51.2973C27.4894 50.8259 28.1329 50.5618 28.8032 50.5618C29.4736 50.5618 30.117 50.8259 30.595 51.2973C31.0729 51.7687 31.3471 52.4095 31.3582 53.0817C31.3563 53.7607 31.0866 54.4113 30.6078 54.8915C30.1291 55.3716 29.4803 55.6423 28.8032 55.6442V55.644Z"/>
              <path d="M35.1053 7.08906H22.8412C22.6841 7.08575 22.5344 7.02168 22.4233 6.91026C22.3122 6.79884 22.2483 6.64866 22.245 6.49114C22.2431 6.41211 22.2572 6.33353 22.2865 6.26013C22.3158 6.18673 22.3596 6.12004 22.4153 6.06414C22.4711 6.00824 22.5376 5.96427 22.6108 5.93491C22.684 5.90555 22.7624 5.89143 22.8412 5.89336H35.1053C35.1809 5.89597 35.2551 5.91354 35.3239 5.94502C35.3927 5.97651 35.4546 6.02129 35.506 6.07685C35.5574 6.1324 35.5974 6.1976 35.6236 6.2687C35.6499 6.3398 35.6618 6.41539 35.6588 6.49114C35.6591 6.79008 35.4037 7.08906 35.1053 7.08906Z"/>
              <path d="M48.9649 30.9179L42.3218 24.0856L43.9825 22.4202L48.9649 27.5448L58.3334 18.0216L60.0368 19.6444L48.9649 30.9179Z" fill="#95C12B"/>
            </g>
            <defs>
              <clipPath id="clip0">
                <rect width="59.8305" height="60" fill="white" transform="translate(0.254272)"/>
              </clipPath>
            </defs>
          </svg>
        </SvgImage>
      </article>
      <article class="item3 info-box">
        <h3 class="article-title">Wygodna spłata</h3>
        <p class="article-text">Na&nbsp;poczet spłaty finansowania pobierany jest stały procent od&nbsp;sprzedaży, aż&nbsp;do momentu osiągnięcia pełnej kwoty. Jeśli w&nbsp;danym miesiącu zarobisz mniej, Twoja rata również będzie niższa. Na&nbsp;spłatę masz aż 13&nbsp;miesięcy.</p>
        <SvgImage>
          <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.74878 36.7562C9.74878 36.4786 9.97376 36.2537 10.2513 36.2537H13.5679C13.8454 36.2537 14.0704 36.4786 14.0704 36.7562C14.0704 37.0337 13.8454 37.2587 13.5679 37.2587H10.2513C9.97376 37.2587 9.74878 37.0337 9.74878 36.7562Z"/>
            <path d="M17.186 36.7562C17.186 36.4786 17.411 36.2537 17.6885 36.2537H21.0051C21.2826 36.2537 21.5076 36.4786 21.5076 36.7562C21.5076 37.0337 21.2826 37.2587 21.0051 37.2587H17.6885C17.411 37.2587 17.186 37.0337 17.186 36.7562Z"/>
            <path d="M24.623 36.7562C24.623 36.4786 24.848 36.2537 25.1256 36.2537H28.4421C28.7197 36.2537 28.9447 36.4786 28.9447 36.7562C28.9447 37.0337 28.7197 37.2587 28.4421 37.2587H25.1256C24.848 37.2587 24.623 37.0337 24.623 36.7562Z"/>
            <path d="M32.0603 36.7562C32.0603 36.4786 32.2853 36.2537 32.5628 36.2537H35.7789C36.0564 36.2537 36.2814 36.4786 36.2814 36.7562C36.2814 37.0337 36.0564 37.2587 35.7789 37.2587H32.5628C32.2853 37.2587 32.0603 37.0337 32.0603 36.7562Z"/>
            <path d="M39.4976 36.7562C39.4976 36.4786 39.7225 36.2537 40.0001 36.2537H43.2162C43.4937 36.2537 43.7187 36.4786 43.7187 36.7562C43.7187 37.0337 43.4937 37.2587 43.2162 37.2587H40.0001C39.7225 37.2587 39.4976 37.0337 39.4976 36.7562Z"/>
            <path d="M46.6331 36.7562C46.6331 36.4786 46.858 36.2537 47.1356 36.2537H50.4522C50.7297 36.2537 50.9547 36.4786 50.9547 36.7562C50.9547 37.0337 50.7297 37.2587 50.4522 37.2587H47.1356C46.858 37.2587 46.6331 37.0337 46.6331 36.7562Z"/>
            <path d="M8.54272 40.0727C8.54272 39.7952 8.76771 39.5702 9.04524 39.5702H12.3618C12.6394 39.5702 12.8643 39.7952 12.8643 40.0727C12.8643 40.3502 12.6394 40.5752 12.3618 40.5752H9.04524C8.76771 40.5752 8.54272 40.3502 8.54272 40.0727Z"/>
            <path d="M16.2815 40.0727C16.2815 39.7952 16.5065 39.5702 16.784 39.5702H20.1006C20.3781 39.5702 20.6031 39.7952 20.6031 40.0727C20.6031 40.3502 20.3781 40.5752 20.1006 40.5752H16.784C16.5065 40.5752 16.2815 40.3502 16.2815 40.0727Z"/>
            <path d="M23.7185 40.0727C23.7185 39.7952 23.9435 39.5702 24.221 39.5702H27.9396C28.2171 39.5702 28.4421 39.7952 28.4421 40.0727C28.4421 40.3502 28.2171 40.5752 27.9396 40.5752H24.221C23.9435 40.5752 23.7185 40.3502 23.7185 40.0727Z"/>
            <path d="M32.0603 40.0727C32.0603 39.7952 32.2853 39.5702 32.5628 39.5702H36.2814C36.5589 39.5702 36.7839 39.7952 36.7839 40.0727C36.7839 40.3502 36.5589 40.5752 36.2814 40.5752H32.5628C32.2853 40.5752 32.0603 40.3502 32.0603 40.0727Z"/>
            <path d="M40.3015 40.0727C40.3015 39.7952 40.5265 39.5702 40.804 39.5702H44.1206C44.3982 39.5702 44.6232 39.7952 44.6232 40.0727C44.6232 40.3502 44.3982 40.5752 44.1206 40.5752H40.804C40.5265 40.5752 40.3015 40.3502 40.3015 40.0727Z"/>
            <path d="M47.8391 40.0727C47.8391 39.7952 48.0641 39.5702 48.3416 39.5702H51.6582C51.9357 39.5702 52.1607 39.7952 52.1607 40.0727C52.1607 40.3502 51.9357 40.5752 51.6582 40.5752H48.3416C48.0641 40.5752 47.8391 40.3502 47.8391 40.0727Z"/>
            <path d="M7.5376 43.3892C7.5376 43.1117 7.76258 42.8867 8.04011 42.8867H11.2562C11.5337 42.8867 11.7587 43.1117 11.7587 43.3892C11.7587 43.6668 11.5337 43.8917 11.2562 43.8917H8.04011C7.76258 43.8917 7.5376 43.6668 7.5376 43.3892Z"/>
            <path d="M15.5779 43.3892C15.5779 43.1117 15.8029 42.8867 16.0804 42.8867H19.2965C19.574 42.8867 19.799 43.1117 19.799 43.3892C19.799 43.6668 19.574 43.8917 19.2965 43.8917H16.0804C15.8029 43.8917 15.5779 43.6668 15.5779 43.3892Z"/>
            <path d="M23.2161 43.3892C23.2161 43.1117 23.441 42.8867 23.7186 42.8867H27.8392C28.1167 42.8867 28.3417 43.1117 28.3417 43.3892C28.3417 43.6668 28.1167 43.8917 27.8392 43.8917H23.7186C23.441 43.8917 23.2161 43.6668 23.2161 43.3892Z"/>
            <path d="M32.3618 43.3892C32.3618 43.1117 32.5868 42.8867 32.8643 42.8867H37.0854C37.363 42.8867 37.5879 43.1117 37.5879 43.3892C37.5879 43.6668 37.363 43.8917 37.0854 43.8917H32.8643C32.5868 43.8917 32.3618 43.6668 32.3618 43.3892Z"/>
            <path d="M41.1055 43.3892C41.1055 43.1117 41.3305 42.8867 41.608 42.8867H44.9246C45.2021 42.8867 45.4271 43.1117 45.4271 43.3892C45.4271 43.6668 45.2021 43.8917 44.9246 43.8917H41.608C41.3305 43.8917 41.1055 43.6668 41.1055 43.3892Z"/>
            <path d="M49.0452 43.3892C49.0452 43.1117 49.2701 42.8867 49.5477 42.8867H52.7638C53.0413 42.8867 53.2663 43.1117 53.2663 43.3892C53.2663 43.6668 53.0413 43.8917 52.7638 43.8917H49.5477C49.2701 43.8917 49.0452 43.6668 49.0452 43.3892Z"/>
            <path d="M49.9086 7.31843C49.3985 7.10907 48.852 7.00257 48.3006 7.00507L48.2984 7.00507H12.6099L12.6089 7.00507C12.0578 7.00393 11.5119 7.11138 11.0023 7.32129C10.4928 7.53118 10.0296 7.83942 9.6392 8.22841C9.24875 8.61744 8.93868 9.07954 8.72694 9.58827C8.51524 10.0969 8.40585 10.6423 8.40493 11.1933V32.3941H52.5036L52.5038 11.1925C52.5042 10.6411 52.3957 10.0951 52.1845 9.58591C51.9732 9.07662 51.6634 8.61413 51.2729 8.22502C50.8823 7.83589 50.4186 7.5278 49.9086 7.31843ZM48.2973 6.00005C48.9806 5.99711 49.658 6.12918 50.2902 6.38868C50.9227 6.64833 51.4978 7.03043 51.9822 7.51303C52.4666 7.99565 52.8508 8.56924 53.1128 9.2008C53.3748 9.83243 53.5093 10.5096 53.5088 11.1933L53.5086 32.8966C53.5086 33.1742 53.2836 33.3992 53.0061 33.3992H7.90242C7.62489 33.3992 7.3999 33.1742 7.3999 32.8966L7.3999 11.1921C7.40098 10.509 7.53656 9.8328 7.79907 9.20208C8.0616 8.57133 8.44596 7.99857 8.92984 7.51646C9.41375 7.03431 9.98784 6.65221 10.6195 6.39201C11.251 6.1319 11.9275 5.9987 12.6104 6.00005H48.2973Z"/>
            <path d="M13.8726 9.11536L13.8733 9.11536L46.9835 9.11536C47.8782 9.11661 48.7359 9.47256 49.3686 10.1052C50.0012 10.7378 50.3572 11.5955 50.3584 12.4902V23.3428C50.3584 23.6203 50.1335 23.8453 49.8559 23.8453C49.5784 23.8453 49.3534 23.6203 49.3534 23.3428V12.4916C49.3525 11.863 49.1024 11.2604 48.6579 10.8159C48.2135 10.3714 47.6109 10.1213 46.9824 10.1204H13.8736C13.2451 10.1213 12.6425 10.3714 12.1981 10.8158C11.7538 11.2602 11.5037 11.8627 11.5028 12.4913V26.9069C11.5064 27.5348 11.7574 28.136 12.2015 28.5801C12.6455 29.0241 13.2466 29.2751 13.8745 29.2788H45.1135C45.391 29.2788 45.616 29.5038 45.616 29.7813C45.616 30.0588 45.391 30.2838 45.1135 30.2838H13.8733L13.8706 30.2838C12.9775 30.2791 12.1223 29.9223 11.4908 29.2907C10.8593 28.6592 10.5025 27.8039 10.4978 26.9108L10.4978 26.9082L10.4978 12.4903C10.499 11.5956 10.8548 10.7379 11.4875 10.1052C12.1201 9.47251 12.9779 9.11658 13.8726 9.11536Z"/>
            <path d="M52.6839 32.4438C52.9339 32.3234 53.2342 32.4285 53.3546 32.6785L59.4641 45.3638C59.7757 45.8204 59.944 46.3599 59.9467 46.9133L59.9467 46.9142C59.9478 47.2714 59.8784 47.6256 59.7421 47.9562C59.6058 48.2867 59.4054 48.5869 59.1527 48.8396C58.9 49.0923 58.5998 49.2926 58.2694 49.4288C57.9393 49.565 57.5854 49.6346 57.2283 49.6336H3.17823C2.82054 49.635 2.466 49.5659 2.13495 49.4303C1.8036 49.2946 1.50213 49.095 1.2479 48.8427C0.993664 48.5905 0.79172 48.2906 0.65346 47.9603C0.515141 47.6299 0.443433 47.2755 0.442142 46.9176L0.442139 46.9156C0.442329 46.3541 0.619405 45.8072 0.947923 45.3525L7.45498 32.6672C7.58164 32.4203 7.88451 32.3228 8.13145 32.4494C8.37839 32.5761 8.47588 32.879 8.34921 33.1259L1.82381 45.847C1.81093 45.8721 1.79595 45.8961 1.77903 45.9187C1.56388 46.2062 1.4475 46.5557 1.44716 46.9149C1.44811 47.1408 1.49341 47.3641 1.58053 47.5722C1.66785 47.7808 1.79535 47.9701 1.95579 48.1293C2.11623 48.2885 2.30656 48.4146 2.51582 48.5003C2.72501 48.5859 2.94912 48.6295 3.17521 48.6286L3.1773 48.6286H57.2288L57.2303 48.6286C57.4553 48.6293 57.6783 48.5855 57.8862 48.4997C58.0941 48.414 58.283 48.288 58.4421 48.1289C58.6012 47.9698 58.7272 47.7809 58.813 47.573C58.8987 47.3652 58.9424 47.1424 58.9417 46.9173C58.9397 46.5555 58.8268 46.203 58.6181 45.9075C58.6021 45.8848 58.588 45.8607 58.5759 45.8357L52.4492 33.1146C52.3287 32.8646 52.4338 32.5642 52.6839 32.4438Z"/>
            <path d="M0 52.8365C0 52.559 0.224983 52.334 0.502513 52.334H59.4975C59.775 52.334 60 52.559 60 52.8365C60 53.114 59.775 53.339 59.4975 53.339H0.502513C0.224983 53.339 0 53.114 0 52.8365Z"/>
            <path d="M17.679 18.6751C17.8959 18.502 18.2121 18.5374 18.3852 18.7543C19.3018 19.9023 20.8825 20.9236 22.9424 21.658C24.9945 22.3897 27.4855 22.823 30.1769 22.823C32.8602 22.823 35.3428 22.3939 37.391 21.6685C39.4466 20.9404 41.0285 19.927 41.9541 18.7859C42.1289 18.5704 42.4454 18.5374 42.6609 18.7122C42.8765 18.8871 42.9095 19.2035 42.7346 19.4191C41.6526 20.753 39.8854 21.8511 37.7265 22.6158C35.5602 23.3831 32.9629 23.828 30.1769 23.828C27.3821 23.828 24.7758 23.3787 22.6048 22.6047C20.4415 21.8334 18.6732 20.7259 17.5998 19.3814C17.4267 19.1645 17.4621 18.8483 17.679 18.6751Z"/>
            <path d="M18.8449 15.9981C17.8451 15.8327 16.819 16.0249 15.9466 16.5407C15.7077 16.682 15.3996 16.6029 15.2583 16.364C15.117 16.1251 15.1961 15.8169 15.435 15.6757C16.5107 15.0395 17.776 14.8026 19.0089 15.0066C19.2828 15.0519 19.468 15.3106 19.4227 15.5844C19.3774 15.8582 19.1187 16.0434 18.8449 15.9981Z"/>
            <path d="M41.4672 15.0064C42.6996 14.8043 43.9639 15.041 45.0398 15.6752C45.2789 15.8161 45.3584 16.1242 45.2175 16.3633C45.0765 16.6024 44.7685 16.6819 44.5294 16.541C43.6562 16.0262 42.6301 15.8341 41.6298 15.9981C41.3559 16.0431 41.0975 15.8574 41.0526 15.5836C41.0077 15.3097 41.1933 15.0513 41.4672 15.0064Z"/>
          </svg>
        </SvgImage>
      </article>
    </div>
    <button-open-dialog />
  </section>
</template>

<script>

import ButtonOpenDialog from '../../../../components/common/ButtonOpenDialog'
import SectionTitle from '../../../../components/common/SectionTitle'
import SvgImage from '../../../../components/common/SvgImage'

export default {
  name: 'InfosSpecificitySection',
  components: {
    SvgImage,
    ButtonOpenDialog,
    SectionTitle
  }
}
</script>
