<template>
  <header class="header">
    <div class="logos">
      <a class="logo-link" href="https://autopay.pl/" title="Idź do strony Autopay"></a>
    </div>
  </header>
</template>

<script>
export default {
  name: 'Header'
}
</script>
