<template>
  <div class="info-icon">
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'SvgImage'
}
</script>

<style lang="scss" scoped>
path {
  fill: var(--main-color);
}
</style>
