<template>
  <section id="scenario-section" class="section">
    <section-title :text="'Dzienne scenariusze spłat dla finansowania w kwocie ' + setScenarioAmount()"/>
    <article id="scenarios" class="article">
      <div class="scenario-legend">
        <span class="scenario-dott"></span><p>Twoja dzienna sprzedaż w Sky-Pay</p>
        <span class="scenario-dott"></span><p>Spłata finansowania ({{ percent }}% dziennej sprzedaży)</p>
      </div>
      <scenario-box v-for="(item, index) in content.scenario" :key="index" :scenario="item" />
    </article>
    <slot></slot>
  </section>
</template>

<script>
import { mapState } from 'vuex'

import { NumberFormatterClass } from '../../../class/calculator.class'
import SectionTitle from '../../../components/common/SectionTitle'
import ScenarioBox from '../../../components/scenarios/ScenarioBox'

export default {
  name: 'ScenarioSection',
  components: {
    SectionTitle,
    ScenarioBox
  },
  props: ['content'],
  computed: {
    ...mapState({
      amount: state => state.scenarios.amount,
      percent: state => state.scenarios.percent
    })
  },
  methods: {
    setScenarioAmount () {
      return NumberFormatterClass.formatAmount(this.amount)
    }
  }
}
</script>
