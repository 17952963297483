<template>
  <div>
    <Header />
    <hero-section />
    <infos-profit-section />
    <partner-info />
    <infos-specificity-section />
    <infos-process-section />
    <calculator-section :content="calculator" />
    <simulation-section :content="simulation" />
    <scenario-section :content="scenarios">
      <button-open-dialog />
    </scenario-section>
    <questions-section />
    <agreements-dialog v-if="isVisible" />
    <button-scroll-up />
  </div>
</template>

<script>
import Header from './components/Header'
import HeroSection from './components/HeroSection'
import InfosSpecificitySection from './components/infos/InfosSpecificitySection'
import InfosProcessSection from './components/infos/InfosProcessSection'
import ScenarioSection from './components/ScenarioSection'
import QuestionsSection from './components/QuestionsSection'

import InfosProfitSection from './components/infos/InfosProfitSection'
import ButtonScrollUp from '../../components/common/ButtonScrollUp'
import AgreementsDialog from '../../components/agreements/AgreementsDialog'
import SimulationSection from '../../components/simulation/SimulationSection'
import CalculatorSection from '../../components/calculator/CalculatorSection'
import ButtonOpenDialog from '../../components/common/ButtonOpenDialog'
import PartnerInfo from './components/PartnerInfo'
import ContentMixin from '../../mixins/content.mixin'

export default {
  name: 'SkyShopLayout',
  components: {
    Header,
    HeroSection,
    InfosSpecificitySection,
    InfosProcessSection,
    ScenarioSection,
    QuestionsSection,
    PartnerInfo,
    InfosProfitSection,
    ButtonScrollUp,
    AgreementsDialog,
    SimulationSection,
    CalculatorSection,
    ButtonOpenDialog
  },
  mixins: [ContentMixin]
}
</script>

<style lang="scss" src="../../assets/scss/partners/sky-shop/_sky-shop.scss"></style>
