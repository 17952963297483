<template>
  <section id="profits-section" class="section">
    <section-title :text="'Co zyskasz wybierając finansowanie od brutto.pl?'" />
    <div id="infos-1" class="grid-container">
      <article class="item1 info-box">
        <h3 class="article-title">Elastyczność</h3>
        <p class="article-text">Finansowanie możesz przeznaczyć na&nbsp;dowolny cel związany z&nbsp;prowadzeniem firmy: podatki, wypłaty dla pracowników, zakup towaru i&nbsp;wiele więcej.</p>
        <SvgImage>
          <svg width="60" height="60" viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0)">
              <path d="M39.445 44.9283C39.0782 44.9308 38.7128 44.8832 38.359 44.7867L29.4823 42.1426C29.3217 42.0893 29.1878 41.9761 29.1086 41.8266C29.0294 41.6771 29.0111 41.5027 29.0573 41.3399C29.0776 41.2554 29.1147 41.176 29.1664 41.1061C29.2182 41.0363 29.2835 40.9776 29.3584 40.9335C29.4333 40.8894 29.5162 40.8608 29.6024 40.8496C29.6885 40.8383 29.7761 40.8445 29.8598 40.8677L38.6895 43.559C40.2006 43.9839 42.0419 43.0401 42.4668 41.6704C42.558 41.3561 42.5841 41.0264 42.5435 40.7017C42.5029 40.377 42.3965 40.0639 42.2308 39.7817C41.8529 39.0259 41.0502 38.4124 40.1531 38.1764L22.9191 32.6992C22.8719 32.6992 21.2665 32.0855 19.85 32.9825C18.3862 33.9269 10.5954 39.4513 10.5482 39.4986C10.4005 39.594 10.2231 39.6322 10.0492 39.6061C9.87537 39.58 9.717 39.4914 9.60384 39.3568C9.51588 39.2032 9.48658 39.0229 9.52132 38.8493C9.55606 38.6757 9.65246 38.5205 9.79277 38.4125C10.076 38.2236 17.6306 32.8409 19.1889 31.8965C21.1721 30.6689 23.25 31.4716 23.3441 31.4716L40.5311 36.9487C41.7114 37.2794 42.7974 38.082 43.364 39.1207C43.6137 39.5612 43.7717 40.0476 43.8285 40.5507C43.8853 41.0538 43.8397 41.5631 43.6945 42.0481C43.1751 43.748 41.3338 44.9283 39.445 44.9283Z" />
              <path d="M38.9725 53.5693C38.6061 53.5657 38.2415 53.5181 37.8865 53.4276L21.9272 48.6588C21.7061 48.5936 21.4736 48.5767 21.2455 48.6093C21.0173 48.6419 20.7989 48.7232 20.6049 48.8477C20.5578 48.8948 18.2914 50.5473 18.2914 50.5473L17.5355 49.4614C19.8491 47.8088 19.8964 47.7617 19.8964 47.7617C20.2449 47.5243 20.6438 47.3709 21.0616 47.3135C21.4794 47.2562 21.9047 47.2964 22.3044 47.4311L38.2165 52.1528C38.9249 52.3416 39.5387 52.3416 40.4831 51.7749C42.0413 50.9251 55.5452 41.7651 55.6868 41.6705L56.1589 41.34L57.4811 40.3485C58.8032 39.4514 59.0865 37.799 58.1893 36.4767C57.2921 35.2019 55.5452 34.9185 54.1282 35.8157L47.1873 40.207C47.116 40.2535 47.0363 40.2854 46.9526 40.3011C46.869 40.3167 46.783 40.3157 46.6997 40.2982C46.5315 40.2628 46.3842 40.1619 46.2903 40.0179C46.1964 39.8739 46.1635 39.6986 46.1989 39.5303C46.2343 39.3621 46.3351 39.2148 46.4792 39.1209L53.42 34.7297C55.403 33.4549 57.953 33.8798 59.2277 35.7684C59.5465 36.1967 59.7739 36.686 59.8958 37.2058C60.0176 37.7257 60.0312 38.265 59.9359 38.7904C59.747 39.8764 59.1332 40.7735 58.236 41.4344L56.4423 42.7565C55.8759 43.1343 42.7021 52.0111 41.1441 52.9082C40.3889 53.3331 39.6806 53.5693 38.9725 53.5693Z"/>
              <path d="M11.4925 57.866C11.382 57.8689 11.2726 57.8445 11.1738 57.795C11.0749 57.7456 10.9898 57.6727 10.9259 57.5825L9.17888 55.08C9.12625 55.013 9.08808 54.9358 9.06662 54.8533C9.04517 54.7708 9.04087 54.6849 9.05417 54.6007C9.06747 54.5165 9.09801 54.4359 9.14385 54.3641C9.18968 54.2922 9.24982 54.2306 9.32055 54.1831C9.60393 53.9941 10.0288 54.0414 10.2177 54.3246L11.6344 56.3077L18.1975 51.6805L8.14024 37.5626L1.57714 42.2373L7.43199 50.4529C7.52739 50.6005 7.5656 50.7779 7.53951 50.9518C7.51342 51.1257 7.42475 51.284 7.2902 51.3972C7.14454 51.4851 6.97097 51.5145 6.80449 51.4795C6.638 51.4444 6.4911 51.3475 6.39324 51.2084L0.113373 42.4731C-0.075583 42.1899 -0.0283318 41.765 0.255045 41.5761L7.90416 36.099C7.97939 36.0481 8.06435 36.0134 8.15368 35.9972C8.24301 35.9809 8.33471 35.9834 8.42303 36.0044C8.56472 36.0044 8.70647 36.0989 8.84816 36.2405L19.6609 51.4442C19.7117 51.5195 19.7463 51.6045 19.7626 51.6939C19.7788 51.7832 19.7763 51.8749 19.7552 51.9632C19.705 52.1169 19.6054 52.2497 19.472 52.341L11.87 57.7709C11.749 57.8193 11.622 57.8513 11.4925 57.866Z"/>
              <path d="M11.5394 24.0586H47.0939V7.95753H11.5394V24.0586ZM47.7546 25.3334H10.9256C10.8382 25.3355 10.7513 25.3198 10.6701 25.2873C10.589 25.2548 10.5152 25.2062 10.4534 25.1444C10.3916 25.0826 10.343 25.0089 10.3105 24.9277C10.2781 24.8466 10.2625 24.7596 10.2646 24.6723V7.3438C10.2625 7.25643 10.2781 7.16948 10.3105 7.08833C10.343 7.00718 10.3916 6.93344 10.4534 6.87163C10.5152 6.80983 10.589 6.76133 10.6701 6.72886C10.7513 6.69639 10.8382 6.68069 10.9256 6.68281H47.7546C47.842 6.68068 47.9291 6.69629 48.0102 6.72875C48.0914 6.76121 48.165 6.80983 48.2268 6.87163C48.2886 6.93344 48.3373 7.00717 48.3698 7.08833C48.4023 7.16948 48.4178 7.25642 48.4156 7.3438V24.6723C48.4178 24.7597 48.4022 24.8466 48.3698 24.9277C48.3374 25.0089 48.2888 25.0827 48.227 25.1445C48.1652 25.2064 48.0914 25.2549 48.0102 25.2874C47.9291 25.3199 47.8422 25.3356 47.7549 25.3335L47.7546 25.3334Z"/>
              <path d="M51.7684 21.2729C51.681 21.275 51.594 21.2594 51.5128 21.2269C51.4316 21.1945 51.3581 21.1459 51.2962 21.0841C51.2344 21.0222 51.1857 20.9485 51.1532 20.8674C51.1208 20.7862 51.1051 20.6993 51.1072 20.6119V4.32217H15.1752C15.0011 4.31849 14.835 4.24772 14.7119 4.12454C14.5887 4.00135 14.5179 3.83534 14.5142 3.66118C14.5121 3.57381 14.5278 3.48686 14.5603 3.40571C14.5927 3.32456 14.6412 3.25081 14.7031 3.18901C14.7649 3.12721 14.8386 3.0787 14.9197 3.04624C15.0009 3.01377 15.0878 2.99807 15.1752 3.00019H51.7684C51.8522 3.00308 51.9345 3.02254 52.0108 3.05736C52.087 3.09219 52.1555 3.1417 52.2126 3.20311C52.2696 3.26453 52.3139 3.33658 52.343 3.41518C52.3721 3.49379 52.3855 3.57743 52.3822 3.66118V20.6121C52.3855 20.6958 52.3721 20.7794 52.343 20.858C52.3139 20.9366 52.2696 21.0086 52.2126 21.07C52.1556 21.1313 52.087 21.1809 52.0108 21.2157C51.9345 21.2505 51.8521 21.27 51.7684 21.2729Z"/>
              <path d="M29.6237 12.8681C28.97 12.8681 28.331 13.062 27.7875 13.4252C27.2439 13.7884 26.8203 14.3046 26.5702 14.9086C26.32 15.5126 26.2545 16.1772 26.382 16.8183C26.5095 17.4595 26.8242 18.0484 27.2864 18.5107C27.7487 18.9729 28.3379 19.2877 28.979 19.4153C29.6202 19.5428 30.2847 19.4774 30.8886 19.2272C31.4926 18.977 32.0089 18.5533 32.3721 18.0098C32.7353 17.4662 32.9291 16.8271 32.9291 16.1734C32.9266 15.2976 32.5774 14.4583 31.9581 13.839C31.3388 13.2197 30.4996 12.8707 29.6237 12.8681ZM29.6237 20.7535C28.4094 20.7524 27.2451 20.2695 26.3864 19.4108C25.5277 18.5522 25.0448 17.3878 25.0437 16.1734C25.0437 13.6239 27.1212 11.5463 29.6237 11.5463C30.231 11.542 30.8331 11.658 31.3954 11.8874C31.9577 12.1169 32.4689 12.4553 32.8998 12.8832C33.3307 13.3111 33.6729 13.82 33.9063 14.3807C34.1397 14.9413 34.2597 15.5426 34.2597 16.1498C34.2597 16.7571 34.1397 17.3584 33.9063 17.9191C33.6729 18.4798 33.3307 18.9887 32.8998 19.4166C32.4689 19.8445 31.9577 20.1829 31.3954 20.4124C30.8331 20.6418 30.231 20.7578 29.6237 20.7535Z"/>
              <path d="M43.4582 15.4181C43.3708 15.4203 43.2837 15.4046 43.2026 15.3721C43.1214 15.3396 43.0478 15.291 42.986 15.2292C42.9242 15.1674 42.8757 15.0937 42.8432 15.0125C42.8108 14.9313 42.7951 14.8444 42.7972 14.757V11.4046C42.7951 11.3172 42.8108 11.2303 42.8432 11.1491C42.8757 11.068 42.9242 10.9942 42.986 10.9324C43.0478 10.8706 43.1217 10.8221 43.2028 10.7896C43.284 10.7572 43.3708 10.7415 43.4582 10.7436C43.5419 10.7465 43.6243 10.766 43.7005 10.8008C43.7768 10.8356 43.8453 10.8851 43.9023 10.9465C43.9594 11.0079 44.0037 11.08 44.0328 11.1586C44.0619 11.2372 44.0753 11.3208 44.072 11.4046V14.757C44.0753 14.8408 44.0619 14.9244 44.0328 15.003C44.0037 15.0817 43.9594 15.1537 43.9023 15.2151C43.8453 15.2765 43.7768 15.3261 43.7005 15.361C43.6243 15.3958 43.5419 15.4152 43.4582 15.4181Z"/>
              <path d="M15.0336 21.2732C14.9462 21.2753 14.8593 21.2596 14.7781 21.2271C14.697 21.1946 14.6233 21.146 14.5615 21.0842C14.4996 21.0224 14.451 20.9487 14.4186 20.8675C14.3861 20.7864 14.3705 20.6994 14.3726 20.6121V17.2596C14.3726 17.0843 14.4423 16.9162 14.5663 16.7922C14.6903 16.6682 14.8584 16.5985 15.0337 16.5985C15.2091 16.5985 15.3772 16.6682 15.5012 16.7922C15.6251 16.9162 15.6947 17.0843 15.6947 17.2596V20.6121C15.6969 20.6995 15.6813 20.7864 15.6488 20.8675C15.6163 20.9487 15.5677 21.0224 15.5059 21.0842C15.4441 21.146 15.3703 21.1948 15.2891 21.2272C15.2079 21.2597 15.121 21.2753 15.0336 21.2732Z"/>
            </g>
            <defs>
              <clipPath id="clip0">
                <rect width="60" height="60"/>
              </clipPath>
            </defs>
          </svg>
        </SvgImage>
      </article>
      <article class="item2 info-box">
        <h3 class="article-title">Swobodę działania</h3>
        <p class="article-text">Dodatkowe środki pozwolą Ci z&nbsp;większym spokojem zarządzać firmą i&nbsp;rozwijać ją w&nbsp;wybranym przez Ciebie kierunku.</p>
        <SvgImage>
          <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M28.9976 59.9998C28.9045 59.9966 28.8131 59.9749 28.7284 59.9363C28.6437 59.8976 28.5673 59.8425 28.5039 59.7742C28.4406 59.706 28.3914 59.626 28.3591 59.5387C28.3268 59.4513 28.3121 59.3585 28.3157 59.2654V17.2549C28.3121 17.1619 28.3267 17.069 28.3591 16.9817C28.3914 16.8944 28.4406 16.8143 28.5039 16.7461C28.5673 16.6779 28.6437 16.6229 28.7284 16.5842C28.8131 16.5455 28.9045 16.5239 28.9976 16.5207C29.0946 16.5184 29.1911 16.5358 29.2812 16.5719C29.3714 16.6079 29.4532 16.6618 29.5218 16.7305C29.5905 16.7991 29.6446 16.881 29.6806 16.9712C29.7167 17.0613 29.7342 17.1579 29.7318 17.2549V59.2654C29.7342 59.3625 29.7167 59.459 29.6806 59.5492C29.6446 59.6394 29.5907 59.7212 29.5221 59.7899C29.4534 59.8586 29.3714 59.9125 29.2812 59.9486C29.1911 59.9847 29.0946 60.0021 28.9976 59.9998Z"/>
            <path d="M30.0463 32.2028C29.9488 32.2078 29.8515 32.1918 29.7609 32.1555C29.6703 32.1193 29.5888 32.0638 29.5218 31.9929C29.3899 31.8467 29.3213 31.6542 29.3311 31.4575C29.341 31.2609 29.4286 31.0762 29.5744 30.9439L37.2839 23.9159C37.4234 23.793 37.6059 23.7301 37.7915 23.7408C37.9771 23.7515 38.1508 23.835 38.2751 23.9732C38.3995 24.1114 38.4643 24.2931 38.4555 24.4788C38.4466 24.6645 38.3648 24.8391 38.2279 24.9649L30.5184 32.0454C30.3842 32.1513 30.2171 32.2069 30.0463 32.2028Z"/>
            <path d="M29.9938 1.46831C21.6021 1.46831 14.7837 11.0668 14.7837 22.867C14.7837 34.7201 21.6021 44.3179 29.9938 44.3179C38.3854 44.3179 45.2033 34.7195 45.2033 22.867C45.2034 11.0662 38.3855 1.46831 29.9938 1.46831ZM29.9938 45.7342C20.8153 45.7342 13.3156 35.5069 13.3156 22.8672C13.3156 10.2798 20.8156 0 29.9938 0C39.1719 0 46.6196 10.2798 46.6196 22.8672C46.6197 35.5068 39.1722 45.7339 29.9938 45.7339V45.7342Z"/>
            <path d="M44.889 59.9998H13.6828C13.4973 59.9863 13.3237 59.9031 13.197 59.7669C13.0704 59.6307 13 59.4516 13 59.2656C13 59.0796 13.0704 58.9004 13.197 58.7642C13.3237 58.628 13.4973 58.5449 13.6828 58.5314H44.889C45.0837 58.5314 45.2704 58.6087 45.408 58.7464C45.5457 58.8841 45.6232 59.0709 45.6232 59.2656C45.6232 59.4603 45.5457 59.6471 45.408 59.7848C45.2704 59.9225 45.0837 59.9998 44.889 59.9998Z"/>
          </svg>
        </SvgImage>
      </article>
      <article class="item3 info-box">
        <h3 class="article-title">Niezależność</h3>
        <p class="article-text">Zabezpieczenie finansowe pomoże Ci podejmować odważne decyzje i&nbsp;osiągać cele, które do&nbsp;tej pory były odkładane na&nbsp;przyszłość.</p>
        <SvgImage>
          <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0)">
              <path d="M10.7325 26.9981C5.40514 26.9981 1.05006 31.3535 1.05006 36.7195C1.05689 39.2853 2.0792 41.7441 3.89354 43.5584C5.70787 45.3728 8.16663 46.3952 10.7325 46.4021C12.4823 46.4021 16.0987 46.4797 19.9483 46.5576C24.459 46.6747 29.0863 46.752 31.6529 46.752C40.6356 46.752 47.9847 39.4416 47.9847 30.4201C47.9847 21.3987 40.6353 14.0883 31.6529 14.0883C27.8157 14.0795 24.0982 15.4238 21.1539 17.8844C18.2096 20.3451 16.2268 23.7648 15.5542 27.5426C15.5542 27.6981 15.4377 27.8147 15.2821 27.8926C15.1367 27.9631 14.9704 27.9769 14.8154 27.9313C13.5438 27.3119 12.1469 26.9927 10.7325 26.9981ZM31.6528 47.8408C29.0863 47.8408 24.42 47.7237 19.9482 47.6462C16.0986 47.5685 12.4433 47.4519 10.7324 47.4519C7.88773 47.4462 5.16126 46.3137 3.14977 44.3022C1.13828 42.2907 0.00568131 39.5641 0 36.7195C0 30.77 4.82127 25.9488 10.7324 25.9488C12.077 25.938 13.4109 26.1887 14.6597 26.6872C16.3707 18.7156 23.4478 12.9999 31.6527 12.9999C41.2573 12.9999 49.0343 20.8159 49.0343 30.4205C49.0343 40.0252 41.2574 47.8408 31.6525 47.8408H31.6528Z"/>
              <path d="M55.1394 39.1303C54.1414 39.1379 53.1865 39.5377 52.4808 40.2434C51.775 40.9492 51.3751 41.9043 51.3676 42.9023C51.3676 45.0022 53.0786 46.7124 55.1394 46.7124C56.1494 46.7107 57.1176 46.3087 57.8318 45.5945C58.546 44.8803 58.948 43.9123 58.9497 42.9023C58.9502 40.8413 57.2393 39.1303 55.1394 39.1303ZM55.1394 47.763C54.1836 47.7592 53.2502 47.4724 52.4571 46.939C51.6639 46.4056 51.0465 45.6493 50.6825 44.7655C50.3184 43.8816 50.2242 42.9098 50.4116 41.9725C50.599 41.0352 51.0596 40.1743 51.7355 39.4984C52.4114 38.8225 53.2723 38.3617 54.2096 38.1743C55.1469 37.9869 56.1187 38.0811 57.0026 38.4452C57.8864 38.8092 58.6427 39.4268 59.1761 40.22C59.7095 41.0131 59.9963 41.9465 60.0001 42.9023C59.9997 44.1913 59.4874 45.4274 58.576 46.3389C57.6645 47.2503 56.4284 47.7625 55.1394 47.763Z"/>
            </g>
            <defs>
              <clipPath id="clip0">
                <rect width="60" height="60" fill="white"/>
              </clipPath>
            </defs>
          </svg>
        </SvgImage>
      </article>
    </div>
  </section>
</template>

<script>

import SectionTitle from '../../../../components/common/SectionTitle'
import SvgImage from '../../../../components/common/SvgImage'

export default {
  name: 'InfosProfitSection',
  components: {
    SvgImage,
    SectionTitle
  }
}
</script>
